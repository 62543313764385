<script setup lang="ts">
const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    breadcrumbs?: any;
  }>(),
  {
    breadcrumbs: [],
  }
);
let { breadcrumbs: initialBreadcrumbs } = useBreadcrumbs();
const breadcrumbs = computed(() =>
  props.breadcrumbs?.length > 0 ? props.breadcrumbs : initialBreadcrumbs.value
);

const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const unifiedBreadcrumbs = computed(() => {
  const homeBreadcrumb = {
    name: t("home"),
    path: "/",
  };

  return breadcrumbs.value && breadcrumbs.value.length > 0
    ? [homeBreadcrumb, ...breadcrumbs.value]
    : breadcrumbs.value;
});

const isBreadcrumbs = computed(() => breadcrumbs.value?.length > 0);
</script>
<template>
  <div class="px-0 py-[1.25rem] bg-skin-body bg-opacity-70 min-h-[60px]">
    <div class="max-w-[90rem] !mx-auto px-0 xl:px-5">
      <ul
        class="breadcrumb !px-0 flex list-none text-skin-light text-sm prose-a:text-skin-primary hover:prose-a:underline flex-wrap"
      >
        <template  v-for="(breadcrumb, index) in unifiedBreadcrumbs"
                   :key="breadcrumb.path">
        <li v-if="unifiedBreadcrumbs.length < 3 || (unifiedBreadcrumbs.length >= 3 && index !== 1)">
          <template v-if="breadcrumb.path">
            <NuxtLink v-if="breadcrumb.path" :to="formatLink(breadcrumb.path)">
              {{ breadcrumb.name }}
            </NuxtLink>
            <span v-if="index !== breadcrumbs.length"
              ><i class="fa-solid fa-angle-right mx-2.5 text-xs"></i
            ></span>
          </template>
          <template v-else>
            <span>
              {{ breadcrumb.name }}
            </span>
            <span v-if="index !== breadcrumbs.length"
              ><i class="fa-solid fa-angle-right mx-2.5 text-xs"></i
            ></span>
          </template>
        </li>
        </template>
      </ul>
    </div>
  </div>
</template>
